import React, { useState, useRef, createRef, useMemo, useEffect } from 'react';
import { flushSync } from 'react-dom';
import '../assets/css/Chat.css'
import { RotatingLines } from 'react-loader-spinner';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { InlineAutocomplete } from 'react-inline-autocomplete';
import ReactMarkdown from 'react-markdown'
import 'react-inline-autocomplete/dist/index.css';

const endpoint =
  process.env.API_ENDPOINT || 'https://lass-kg-api.demos.dice-research.org';

export default function Chat() {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');
    const [completions, setCompletions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [sessionId, setSessionId] = useState('');
    const sessionIdRef = useRef('');
    const messagesRef = useRef(null);
    const inputRef = createRef(null);

    const firstMessage = useMemo(() => {
      return t('first_message');
    }, [t]);
    
    // useEffect for setting the session ID/sessionIdRef.current
    useEffect(() => {
      if (!sessionIdRef.current) {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        sessionIdRef.current = newSessionId;
        console.log('Session ID created:', newSessionId); // Debugging log
      }
      }, []);

    useEffect(() => {
      setMessages((messages) => {
        if (messages.length === 0) {
          return [{ inputText: firstMessage, isUser: false }];
        } else {
          // Only update the first message when the language changes
          const updatedMessages = [...messages];
          updatedMessages[0].inputText = firstMessage;
          return updatedMessages;
        }
      });
      
    }, [firstMessage]);
  
    const handleChange = (event) => {
      setInputText(event);
    };
  
    const fetchData = async (input) => {
      setLoading(true);
      const query_result = await fetch(endpoint + '/v2/agent-search?query=' + input + '&session_id=' + sessionId, {
        method: 'POST',
      });
      const data = await query_result.json();
      setLoading(false);
      return data;
    };
  
    const onSendClick = async (e) => {
      e.preventDefault(); // prevent page reload
  
      if (!inputText) {
        alert('Input is empty!');
        return; // exit the function if input is empty
      }
  
      const input = inputText;
  
      setInputText(''); // clear the input
       // this function waits until the DOM is updated
      flushSync(() => {
         // set messages
        setMessages((messages) => [
          ...messages,
          { inputText: input, isUser: true },
        ]);
      });
  
      messagesRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      }); // scroll to the bottom of the chat
      // send POST request to the server
      let result = await fetchData(input);
      const answer = result.answer;
      const papers = result.abstracts.map((abstract) => ({
        content: abstract.content,
        identifier: abstract.identifier,
      }))
      flushSync(() => {
        setMessages((messages) => [
          ...messages,
          { inputText: answer, isUser: false },
          ...papers.map((paper) => ({
            inputText: paper.content,
            isUser: false,
          }))
        ]);
      });
  
      messagesRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      }); // scroll to the bottom of the chat
  
      result = await fetch(endpoint +'/v1/suggest?query=' + input + '&n=5');
      result = await result.json();
      if (result.suggestions.length > 0) {
        setSuggestions(result.suggestions);
      }
    };
    
    // Modified getAutoCompletions function to use POST request
    const getAutoCompletions = async (input) => {
      const value = input;
      //e.preventDefault() // prevent page reload
  
      if (value.length > 0) {
        const sessionId = sessionIdRef.current;
        if (!sessionId) {
          console.error('Session ID is not set');
          return;
        }
        console.log('Making request with Session ID:', sessionId); // Debugging log

        let result = await fetch(endpoint + '/v1/autocomplete?query=' + value, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Session-Id': sessionId,
          },
          body: JSON.stringify({ query: value }),
        });
        console.log('Session ID sent in request:', sessionId); // Debugging log
        
        result = await result.json();
        if (result.completion != null && result.completion.length > 0) {
          setCompletions((completions) => [
            {
              text: result.completion,
              value: result.completion,
            },
            ...completions,
          ]);
          console.log('Compleiton: ' + result.completion);
        } else {
          //setCompletions([])
          console.log('no completion');
        }
      } else {
        setCompletions([]);
      }
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      const sessionId = sessionIdRef.current;
      if (!sessionId) {
        console.error('Session ID is not set');
        return;
      }
    
      console.log('Making request with Session ID:', sessionId); // Debugging log
    
      let result = await fetch(endpoint + '/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Session-Id': sessionId,
        },
        body: JSON.stringify({ query: inputText }),
      });
    
      result = await result.json();
      console.log('Response:', result);
    };

    //Input key press event handler
    const onInputKeyPress = (e) => {
      if (e.keyCode === 13) {
        onSendClick(e);
      }
    };
  
    const onSuggestionsClick = (e) => {
      setInputText(e.target.value);
      setSuggestions([]);
    };
  
    const debouncedFetch = useMemo(() => debounce(getAutoCompletions, 300), []);
  
    useEffect(() => {
      debouncedFetch(inputText);
      // eslint-disable-next-line
    }, [inputText]);
  

  return (
    <div className='container' style={{ pointerEvents: loading ? 'none' : 'auto', opacity: loading ? 0.5 : 1 }}>
      <div className='row'>
        <div className='col col-lg-12 chat-window' style={{ paddingLeft: 0 }}>
          <div className='top-menu'>
            <div className='top-wrapper'>
              <div className='title'>
                <img
                  alt='Springer Materials logo'
                  className='logo'
                  src={
                    process.env.PUBLIC_URL + '/img/Springer_Materials_logo.svg'
                  }
                />{' '}
                 {t('guided_search')}
              </div>
            </div>
            {loading && 
                    <div className="loader-container">
                      <div className="loader">
                        <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                      </div>
                      <p className="loader-text">{t('processing_your_question')}</p>
                    </div>
             }
            <ul ref={messagesRef} className='messages'>
              {messages.map((message, index) => {
                return (
                  <li
                    key={index}
                    className={
                      message.isUser
                        ? 'message right appeared'
                        : 'message left appeared'
                    }
                  >
                    <div className='text-wrapper'>
                      <div
                        className='text'
                      >
                        <ReactMarkdown 
                            children={message.inputText}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div
              className='bottom-wrapper bw-chat clearfix'
              style={{ display: 'block' }}
              onKeyUp={onInputKeyPress}
            >
              <div className='message-input-wrapper'>
                 {/*<input ref={inputRef} className="message-input" placeholder="Ethanol boiling point, phase diagram" onChange={onInputChange} value={inputText}/>*/}

                <InlineAutocomplete
                  ref={inputRef}
                  className='message-autocomplete'
                  style={{
                    border: 'none',
                    height: '100%',
                    boxSizing: 'border-box',
                    width: 'calc(100% - 40px)',
                    position: 'absolute',
                    outlineWidth: 0,
                    color: 'gray',
                  }}
                  value={inputText}
                  dataSource={completions}
                  onChange={handleChange}
                  caseSensitive={false}
                ></InlineAutocomplete>
              </div>
              <button className='send-message' onClick={onSendClick}>
                <div className='icon'></div>
                <div className='text'></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div
          className='col col-lg-12'
          style={{ marginTop: '30px' }}
        >
          {suggestions.map((suggestion, index) => {
            return (
              <button
                key={index}
                className='btn btn-primary btn-suggestion'
                value={suggestion}
                onClick={onSuggestionsClick}
                title={t('use_suggestion')} 
              >
                {suggestion}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}