import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./i18n.js";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <React.Suspense fallback="loading...">
        <App />
      </React.Suspense>
    </React.StrictMode>
  );
